import React from "react"
import { Provider } from "react-redux"
import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/integration/react";
import reducer from './src/redux-store/reducer'

export const wrapRootElement = ({ element }) => {
  const persistConfig = {
    key: "root",
    storage,
  };

  const persistedReducer = persistReducer(persistConfig, combineReducers({user: reducer}));

  const store = configureStore({ reducer: persistedReducer });
  const persistor = persistStore(store);
  return <Provider store={store}>
    <PersistGate loading={<div />} persistor={persistor}>
      {element}
    </PersistGate>
  </Provider>
}

