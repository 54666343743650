// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-documenti-js": () => import("./../../../src/pages/documenti.js" /* webpackChunkName: "component---src-pages-documenti-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-iscriviti-js": () => import("./../../../src/pages/iscriviti.js" /* webpackChunkName: "component---src-pages-iscriviti-js" */),
  "component---src-templates-articolo-index-js": () => import("./../../../src/templates/articolo/index.js" /* webpackChunkName: "component---src-templates-articolo-index-js" */),
  "component---src-templates-categoria-index-js": () => import("./../../../src/templates/categoria/index.js" /* webpackChunkName: "component---src-templates-categoria-index-js" */),
  "component---src-templates-comunicato-index-js": () => import("./../../../src/templates/comunicato/index.js" /* webpackChunkName: "component---src-templates-comunicato-index-js" */),
  "component---src-templates-lista-articoli-index-js": () => import("./../../../src/templates/lista-articoli/index.js" /* webpackChunkName: "component---src-templates-lista-articoli-index-js" */),
  "component---src-templates-lista-video-index-js": () => import("./../../../src/templates/lista-video/index.js" /* webpackChunkName: "component---src-templates-lista-video-index-js" */),
  "component---src-templates-video-index-js": () => import("./../../../src/templates/video/index.js" /* webpackChunkName: "component---src-templates-video-index-js" */)
}

