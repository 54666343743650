import {createSlice} from '@reduxjs/toolkit'

const user = createSlice({
  name: 'user',
  initialState: {
    isLogged: false,
    showLoginDialog: false,
    token: null,
  },
  reducers: {
    setIsLogged: (state, {payload}) => {
      state.isLogged = payload;
    },
    setShowLoginDialog: (state, {payload}) => {
      state.showLoginDialog = payload;
    },
    setToken: (state, {payload}) => {
      state.token = payload.token;
    }
  }
});

const {actions, reducer} = user;

export {actions, reducer}

export default reducer
